import { Link } from '@inertiajs/react';
import Vapor from 'laravel-vapor';
import { useTranslation } from 'react-i18next';

import { Charity } from '../../Shared/types';
import { useRouter } from '../router';
import Container from './Container';

export function Footer({
  charity,
}: {
  charity?: Charity;
}) {
  const { t } = useTranslation();
  const { routes } = useRouter();

  return (
    <Container className="!pt-0">
      <hr className="border-slate-200" />
      <div className="text-center">
        {charity && (
          <div className="mb-6 space-x-4 text-sm">
            <Link href={routes.help_page()}>
              {t('frontend:need_help')}
            </Link>
            <Link href={routes.privacy_page()}>
              {t('frontend:privacy_statement')}
            </Link>
          </div>
        )}

        <a
          href="https://supporta.cc"
          target="_blank"
          rel="noopener noreferrer"
          className="opacity-60"
        >
          <img src={Vapor.asset('images/logo-mono.svg')} width="80" height="14" className="block mx-auto" alt="Supporta" />
        </a>
      </div>
    </Container>
  );
}
