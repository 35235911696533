
import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';

import { transparentizeColor } from '../helpers';

interface ProgressBarProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  current: number;
  target?: number | null;
  brandColor?: string | null;
  rounded?: boolean;
}

export function ProgressBar({ current, target, brandColor, rounded = true, className, ...props }:ProgressBarProps) {
  const [width, setWidth] = useState('0');
  const progress = target ? Math.min(current / target, 1) : 0;

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setWidth(`${progress * 100}%`);
    }, 250);

    return () => {
      window.clearTimeout(timer);
    };
  }, [width]);

  return (
    <div className="bg-white">
      <div
        className={classNames(
          'h-1',
          rounded && 'rounded',
          className
        )}
        {...props}
        style={{
          background: brandColor ? transparentizeColor(brandColor, 0.85) : 'rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          className={classNames(
            'h-full',
            rounded && 'rounded',
            !brandColor && 'bg-emerald-500',
          )}
          style={{
            width,
            background: brandColor || undefined,
            transition: `width ${progress * 2}s ease-in-out`,
          }}
        />
      </div>
    </div>
  );
}
