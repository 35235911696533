import { usePage } from '@inertiajs/react';
import { ReactNode } from 'react';

import { TranslationsProvider } from '../../Shared/locale';
import NotificationProvider from '../../Shared/Notifier/NotificationProvider';
import { Charity } from '../../Shared/types';
import { PageProps } from '../types';
import EmbedProvider from './EmbedProvider';
import { Footer } from './Footer';

export default function Layout({
  children,
}: {
  children?: ReactNode;
}) {
  const { props: { charity } } = usePage<PageProps<{ charity?: Charity; }>>();

  return (
    <EmbedProvider>
      <TranslationsProvider>
        <NotificationProvider>
          {children}
          <Footer charity={charity} />
        </NotificationProvider>
      </TranslationsProvider>
    </EmbedProvider>
  );
}
