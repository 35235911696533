import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

type HeaderProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function Header({
  className,
  children,
  ...props
}: HeaderProps) {

  return (
    <div
      className={classNames(
        'bg-project/5',
        className,
      )}
      {...props}
    >
      <div className="border-b border-b-black/5 z-0">
        {children}
      </div>
    </div>
  );
}
