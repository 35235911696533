import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

type ContainerProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function Container({
  className,
  children,
  ...props
}: ContainerProps) {

  return (
    <div
      className={classNames(
        'max-w-2xl mx-auto py-6 px-4 sm:px-8 space-y-6',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}
