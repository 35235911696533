import { usePage } from '@inertiajs/react';

import { PageProps } from './types';

function path(...segments: (string | number)[]) {
  return ['', ...segments].join('/');
}

export function useRouter() {
  const { props: { charity }, url } = usePage<PageProps>();
  const charityId = charity?.id || '';

  const routes = {
    // Dashboard
    'dashoard_page': () => '/me',

    // Charity
    'charity_page': (charityId: string) => path(charityId),

    // Project
    'project_page': (projectId: string) => path(charityId, 'projects', projectId),
    'register_page': (projectId: string) => path(charityId, 'projects', projectId, 'register'),

    // Event
    'event_page': (eventId: string) => `/events/${eventId}`,
    'join_event_page': (eventId: string, charityId: string) => `/events/${eventId}/register/${charityId}`,

    // Campaign
    'campaign_page': (campaignId: string) => path(charityId, campaignId),
    'campaign_products_page': (campaignId: string) => path(charityId, campaignId, 'products'),
    'edit_campaign_page': (campaignId: string) => path(charityId, campaignId, 'edit'),
    'edit_campaign': (campaignId: string) => path(charityId, campaignId, 'edit'),
    'join_team': (teamId: string) => path(charityId, teamId, 'join'),
    'leave_team': (campaignId: string) => path(charityId, campaignId, 'leave_team'),
    'delete_campaign': (campaignId: string) => path(charityId, campaignId, 'delete'),
    'add_product': (campaignId: string) => path(charityId, campaignId, 'products'),
    'edit_product': (campaignId: string, productId: string) => path(charityId, campaignId, 'products', productId, 'edit'),
    'toggle_product': (campaignId: string, productId: string) => path(charityId, campaignId, 'products', productId, 'toggle'),
    'delete_product': (campaignId: string, productId: string) => path(charityId, campaignId, 'products', productId, 'delete'),
    'donation_page': (campaignId: string) => path(charityId, campaignId, 'donate'),
    'donate': (campaignId: string) => path(charityId, campaignId, 'donate'),
    'add_campaign': () => path(charityId, 'campaigns'),

    // Checkout
    'complete_test_checkout': (donationId: string) => path(charityId, 'test-checkout', donationId, 'complete'),

    // Donation
    'edit_billing_info': (donationId: string, token: string) => path(charityId, 'donations', donationId, token, 'edit_billing_info'),

    // Misc
    'help_page': () => path(charityId, 'help'),
    'privacy_page': () => path(charityId, 'privacy'),

    // Auth
    'login_page': () => '/login',
    'logout': () => '/logout',

    // Back-end
    'backend_page': (charityId: string) => path('b', charityId),
  };

  const isActive = (route: string, exact = false) => {
    const path = url.split('?')[0];

    if (exact) {
      return path === route;
    }

    return path.startsWith(route);
  };

  return {
    routes,
    isActive,
  };
}
